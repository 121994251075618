import { Link } from "react-router-dom";
import React from "react";

const IncidentHoverWrapper = ({ children, incidentId, className = "" }) => {
  if (incidentId) {
    return (
      <Link
        className={"w-full rounded ${className}"}
        to={`/incidents/${incidentId}`}
      >
        {children}
      </Link>
    );
  }
  return children;
};

export default IncidentHoverWrapper;
